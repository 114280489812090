import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authenticationApi } from "../containers/Authentication/service";
import { adminApi, internalService as adminInternalApi } from "../containers/Admin/services";
import { adminFeaturesReducer } from "../containers/Admin/features";
import { modalReducer as mpModalReducer } from "../containers/ManagementPortal/features";
import { commonReducer } from "./features";
import { managementPortalApi, pluginApi } from "../containers/ManagementPortal/services";
import {
  trackingApi,
  reverseGeoCodingApi,
  vehicleRouteApi,
  tripsTrackApi,
  tripsApi,
  eventsApi,
  rawDataApi,
  variablesApi,
} from "../containers/Tracking/services";

export const store = configureStore({
  reducer: {
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [managementPortalApi.reducerPath]: managementPortalApi.reducer,
    [trackingApi.reducerPath]: trackingApi.reducer,
    [reverseGeoCodingApi.reducerPath]: reverseGeoCodingApi.reducer,
    [adminInternalApi.reducerPath]: adminInternalApi.reducer,
    [vehicleRouteApi.reducerPath]: vehicleRouteApi.reducer,
    [tripsApi.reducerPath]: tripsApi.reducer,
    [tripsTrackApi.reducerPath]: tripsTrackApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [rawDataApi.reducerPath]: rawDataApi.reducer,
    [variablesApi.reducerPath]: variablesApi.reducer,
    [pluginApi.reducerPath]: pluginApi.reducer,

    adminFeaturesReducer,
    mpModal: mpModalReducer,
    common: commonReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authenticationApi.middleware,
      adminApi.middleware,
      managementPortalApi.middleware,
      trackingApi.middleware,
      reverseGeoCodingApi.middleware,
      adminInternalApi.middleware,
      vehicleRouteApi.middleware,
      tripsApi.middleware,
      tripsTrackApi.middleware,
      eventsApi.middleware,
      rawDataApi.middleware,
      variablesApi.middleware,
      pluginApi.middleware
    ),
});

setupListeners(store.dispatch);
