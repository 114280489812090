import React, { useState, useEffect } from "react";
import { CommonHeader } from "../Shared/Header";
import { NavLink } from "react-router-dom";
import { NativeSvgIcon } from "../Admin/Shared/";
import { English, Bahasa, Malay, Chinese } from "../../lang";
import selectedLanguage from "../../shared/selectedLanguage.json";
import { QIFlashScreen } from "../../components";
import { FMDPStorage } from "../../shared/helper";
import { useDispatch, useSelector } from "react-redux";
import { useSelectClientAppMutation } from "../Authentication/service";
import { PluginsLayout } from "../Shared/PluginsLayout";

import "./style/App.scss";
import { setlastVisitedApp } from "../../reduxStore/features";
import { getLocalizedString } from "../../shared/translation";
import { useGetContainersQuery, useGetPluginsQuery } from "../ManagementPortal/services";
import { usePagination } from "../../hooks";
import BytesizeIcon from "../ManagementPortal/Shared/BytesizeIcon";

export const TrackingLayout = ({ children, ...props }) => {
  const [selectedAppId, setLayoutSelectedAppId] = useState(FMDPStorage.get("selected-app-id"));
  const [appToToggle, setAppToToggle] = useState(selectedAppId);
  const [switchClicked, setSwitchClicked] = useState(false);
  const dispatch = useDispatch();

  const flashScreenStatus = useSelector((state) => state.common.flash_screen);

  const currentUserLanguage = FMDPStorage?.get("current-user")?.locale?.code
    ? FMDPStorage?.get("current-user")?.locale?.code
    : "en";

  const currentUser = FMDPStorage?.get("current-user")?.client_apps;

  const [
    selectClientApp,
    {
      isSuccess: selectClientAppSuccess,
      data: clientAppData,
      reset: resetSelectClientApp,
      error: selectClientAppError,
    },
  ] = useSelectClientAppMutation({
    fixedCacheKey: "shared-client-app-data",
  });

  const iDforClient = currentUser?.find((user, id) => {
    return user.id === selectedAppId;
  })?.client?.id;

  const filteredClients = currentUser?.filter((user) => {
    return user?.client?.id === iDforClient;
  });

  useEffect(() => {
    if (switchClicked) {
      try {
        if (filteredClients.length > 1) {
          const toggleId = filteredClients?.filter((item) => {
            return item.id !== selectedAppId;
          });
          setAppToToggle(toggleId[0]?.id);

          // Execute selectClientApp only if appToToggle has changed
          if (toggleId[0]?.id !== appToToggle) {
            selectClientApp({ client_app_id: toggleId[0]?.id });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [selectedAppId, currentUser, switchClicked, filteredClients]);

  useEffect(() => {
    switch (currentUserLanguage) {
      case "en":
        selectedLanguage.Strings = English;
        break;
      case "id":
        selectedLanguage.Strings = Bahasa;
        break;
      case "ms":
        selectedLanguage.Strings = Malay;
        break;
      case "zh-TW":
        selectedLanguage.Strings = Chinese;
        break;

      default:
        console.log(
          "The application will use English as the following locale not supported: ",
          currentUserLanguage
        );
        selectedLanguage.Strings = English;
        break;
    }
  }, [currentUserLanguage]);

  let leftPanelLinks = [
    { label: getLocalizedString("dashboard", "Dashboard"), path: "dashboard", icon: "dashboard" },
    { label: getLocalizedString("vehicles", "Vehicles"), path: "vehicles", icon: "vehicle" },
    { label: getLocalizedString("devices", "Devices"), path: "devices", icon: "device" },
    { label: getLocalizedString("trips", "Trips"), path: "trips", icon: "geofence" },
    {
      label: getLocalizedString("activities", "Activities"),
      path: "activities",
      icon: "vehicle-activity",
    },
    { label: getLocalizedString("events", "Events"), path: "events", icon: "event" },
    { label: getLocalizedString("reports", "Reports"), path: "reports", icon: "audit-log" },
  ];

  //const { data: newContainers } = useGetContainersQuery();

  //const tcontainers = newContainers?.filter((container) => container.isManagementPortal === false);

  const { page, setPage, perPage, pagination } = usePagination();

  const { data = { data: [], total_count: 0 }, isSuccess } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  /* const addContainerEntity = {
    label: getLocalizedString("add_container", "Add Container"),
    path: "add-container",
    icon: "add",
  }; */

  /* const newContainerEntities =
    tcontainers?.map((container) => {
      const firstPluginPath = data?.data?.find(
        (plugin) => plugin.id === container.plugins[0]
      )?.path;

      return {
        label: getLocalizedString(container.name, container.name),
        path: firstPluginPath ? `plugin/${firstPluginPath}` : "plugin/unknown",
        icon: container.icon,
      };
    }) || []; */

  leftPanelLinks = [...leftPanelLinks];

  //dispatch client
  useEffect(() => {
    if (selectedAppId) {
      dispatch(setlastVisitedApp(selectedAppId));
    }
  }, [selectedAppId, dispatch]);

  return (
    <QIFlashScreen currentAppId={selectedAppId} flashScreenStatus={flashScreenStatus}>
      <>
        <CommonHeader
          setLayoutSelectedAppId={setLayoutSelectedAppId}
          appToToggle={appToToggle}
          appName="Tracking"
        />

        <main className="tracking-app-container-wrapper">
          <aside className="qi-nav-bar primary left">
            <nav className="qi-nav-bar_navigation">
              {/* Enabled MP */}
              {filteredClients.length > 1 && (
                <div
                  onClick={() => {
                    setSwitchClicked(true);
                  }}
                  key={Math.random()}
                  className={`qi-nav-bar_navigation_item`}
                >
                  <NativeSvgIcon name={"management"} />
                  <div className="qi-nav-bar_navigation_item_info">
                    <span className="">
                      {getLocalizedString("management_portal", "Management Portal")}
                    </span>
                  </div>
                </div>
              )}

              {/* Disabled Tracking */}
              {filteredClients.length > 1 && (
                <div
                  onClick={() => {}}
                  key={Math.random()}
                  className={`qi-nav-bar_navigation_item active`}
                >
                  <NativeSvgIcon name={"tracking"} />
                  <div className="qi-nav-bar_navigation_item_info">
                    <span className="">{getLocalizedString("tracking", "Tracking")}</span>
                  </div>
                </div>
              )}

              <ul className="qi-nav-bar_navigation_sub-nav">
                {leftPanelLinks.map((section, index) => (
                  <li className="qi-nav-bar_navigation_sub-nav_item">
                    <NavLink
                      key={index}
                      to={`/tracking/${section.path}`}
                      className={`qi-nav-bar_navigation_sub-nav_item_link`}
                    >
                      {!section.path.startsWith("plugin") && (
                        <NativeSvgIcon name={`${section.icon}`} />
                      )}
                      {section.path.startsWith("plugin") && <BytesizeIcon name={section.icon} />}
                      <div className="qi-nav-bar_navigation_sub-nav_item_info">
                        <span className="">{section.label}</span>
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="copyright">
              <span>&copy;</span>
              <p className="copyright_info">
                {getLocalizedString("copy_right", "Copyright")} &copy;
                {new Date().getFullYear()}{" "}
                {getLocalizedString(
                  "fleet_management_all_rights_reserved",
                  "Fleet Management. All rights reserved."
                )}
              </p>
            </div>
          </aside>
          <div className="tracking-app-main-container-wrapper">{children}</div>
        </main>
      </>
    </QIFlashScreen>
  );
};
