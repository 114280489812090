import { useParams } from "react-router-dom";
import { usePagination } from "../../../hooks";
import { useGetPluginsQuery } from "../services";
import { getLocalizedString } from "../../../shared/translation";
import { MPSharedHeader } from "../Shared";
import { PluginContainer } from "../../Plugins";

export const MPRenderDynamicTab = () => {
  let params = useParams();

  const { page, setPage, perPage, pagination } = usePagination();

  const groupName = params.groupName;

  const {
    data = { data: [], total_count: 0 },
    isSuccess,
  } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  let selectedPlugin;

  if (!params.pluginPath) {
    selectedPlugin = data.data.find((plugin) =>
        plugin.id === selectedContainer.plugins[0]
      );
  } else {
    selectedPlugin = data.data.find((plugin) =>
        plugin.path === params.pluginPath
    );
  }

  if (!selectedPlugin && isSuccess) {
    return <div>{getLocalizedString("No plugin found for the selected container or invalid plugin path.", 
        "No plugin found for the selected container or invalid plugin path.")}</div>;
  }

  const headerNames = {
    "device": "Manage Devices",
    "vehicle": "Manage Vehicles",
    "group": "Manage Groups",
    "configurations": "Configurations",
    "users_role": "Users and Roles"
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName={groupName}
          resourceName={`${groupName}`}
          heading={getLocalizedString(`${headerNames[groupName]}`, `${headerNames[groupName]}`)}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
        />
        <PluginContainer selectedPlugin={selectedPlugin} isSuccess={isSuccess} data={data} />
      </article>
    </>
  ); 
};
