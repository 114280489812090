import { useParams } from "react-router-dom";
import { PluginContainer } from "../../Plugins";
import { useGetPluginsQuery, useGetAppsQuery } from "../../ManagementPortal/services";
import { usePagination } from "../../../hooks";
import { FMDPStorage } from "../../../shared/helper";

export const TPluginContainer = () => {
  let params = useParams();

  const userApp = FMDPStorage.get("selected-app");

  const { page, setPage, perPage, pagination } = usePagination();

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  // Get list of apps subscribed by client
  const { data: clientApp = { data: [] }, isSuccess: clientAppSuccess } = useGetAppsQuery();

  const isPluginSupportedByApp = (plugin) =>
    plugin.permission.map((app_id) => clientApp.data[app_id - 1]?.name
      .replace(/\s+/g, '')).includes(userApp);

  const selectedPlugin = data.data.find((plugin) =>
    plugin.path === params.path && isPluginSupportedByApp(plugin)
  );

  return (
    <main className="tab-content grow">
      <PluginContainer selectedPlugin={selectedPlugin} />
    </main>
  );
};
