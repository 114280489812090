import { useFormik } from "formik";
import { useState, useEffect } from "react";
import {
  QIInput,
  QIPhotoUploader,
  QIButton,
  QIModalBody,
  QIModal,
  QIModalFooter,
} from "../../../../components";
import { useGetClientConfigQuery, useUpdateClientConfigMutation } from "../../services";
import { parseServerError, mergeObjects } from "../../../../shared/helper";
import { SvgIcon } from "../../../Shared";
import { userImg } from "../../../../assets";
import { clientConfigSchema } from "../../validations";
import {getLocalizedString} from "../../../../shared/translation";

export const ClientConfig = () => {
  const [editMode, setEditMode] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    image: "",
    welcome_msg: "",
    retention_period: "",
    // config_json: "{}",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: clientConfigSchema,
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  // Get client config from BE
  const { data } = useGetClientConfigQuery({});

  const [
    update,
    {
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: updateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateClientConfigMutation();

  const handleSubmit = (formData) => {
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "image" && formData.image == null) {
        fObj.append("remove_image", true);
      } else if (!(key === "image" && formData.image === "") && formData[key] != null) {
        fObj.append(key, formData[key]);
      }
    });

    update({ formData: fObj });
  };

  useEffect(() => {
    if (updateError) {
      formik.setErrors(parseServerError(updateError));
    }
  }, [updateError]);

  const cancelUpdate = () => {
    formik.setValues(mergeObjects(formik.initialValues, data));
    formik.setFieldValue("image", null);
    setEditMode(false);
  };

  const toggleEditMode = () => {
    if (editMode) {
      cancelUpdate();
      // formik.setFieldValue("config_json", "{}");
    } else {
      // formik.setFieldValue("config_json", "{}");
      setEditMode(true);
    }
  };

  // Set the values for edit form
  useEffect(() => {
    if (data) {
      let tempData = { ...data };
      // if (tempData.config_json) {
      //   try {
      //     let parsed = JSON.parse(tempData.config_json);
      //     if (!parsed) {
      //       tempData.config_json = "{}";
      //     }
      //   } catch {
      //     tempData.config_json = "{}";
      //   }
      // }
      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [data]);

  useEffect(() => {
    if (updateSuccess) {
      setShowSuccessModal(true);
      setEditMode(false);
    }
  }, [updateSuccess]);

  return (
    <>
      <div className="customization_block">
        <header className="customization_block_header">
          <strong>
            {getLocalizedString("organizational_customization", "Organizational Customization")}
          </strong>
          <span className="edit">
            <SvgIcon name="edit" wrapperClass="mx-2" onClick={toggleEditMode} />
          </span>
        </header>
        <div>
          <div className="customization_block_header_brand">
            <QIPhotoUploader
              label={getLocalizedString("logo", "Logo")}
              placeHolderImg={userImg}
              src={formik.values.image}
              onChange={(files) =>
                formik.setFieldValue("image", files?.length > 0 ? files[0] : null)
              }
              readOnly={!editMode}
              disabled={!editMode}
              showJustImage
              base64={false}
              initalImageUrl={formik.values.image_url}
              key={editMode}
              error={formik.touched.image && formik.errors.image}
              photoGuide={
                editMode &&
                `
                <h4 className="font-semibold">${
                  getLocalizedString("image_upload_guide", "Image Upload guide")
                } - </h4>
                <div className="ml-2">
                  <p>${getLocalizedString("max_size", "Max size")} 40kb</p>
                  <p>${
                    getLocalizedString("file_format", "File format")
                  } .jpg/ .jpeg/ .png/ .gif</p>
                  <p>${
                    getLocalizedString("preferred_image_aspect_ratio", "Preferred image aspect ratio")
                  } 1:1</p>
                </div>
              `
              }
            />
          </div>
          <div>
            <QIInput
              label={getLocalizedString("welcome_message", "Welcome Message")}
              {...formik.getFieldProps("welcome_msg")}
              readOnly={!editMode}
              disabled={!editMode}
            />
            <QIInput
              label={getLocalizedString("retention_period", "Retention Period (Days)")}
              {...formik.getFieldProps("retention_period")}
              error={formik.touched.retention_period && formik.errors.retention_period}
              readOnly={!editMode}
              disabled={!editMode}
            />

            {/* <QIInput
              label="Additional Features (JSON)"
              textarea={true}
              {...formik.getFieldProps("config_json")}
              error={formik.touched.config_json && formik.errors.config_json}
              disabled={true}
            /> */}

            {editMode && (
              <div className="user-profile-details_footer pt-4">
                <QIButton className="secondary sm mr-2 " onClick={cancelUpdate}>
                  {getLocalizedString("cancel", "Cancel")}
                </QIButton>

                <QIButton className="primary sm" onClick={formik.handleSubmit}>
                  {getLocalizedString("update", "Update")}
                </QIButton>
              </div>
            )}
          </div>
        </div>
      </div>

      <QIModal show={showSuccessModal} className="confirm">
        <QIModalBody>
          <p className="text-center">
            {getLocalizedString("organizational_customization_updated_successfully", "Organizational Customization Updated Successfully")}
          </p>
        </QIModalBody>
        <QIModalFooter>
          <QIButton className="qi-btn primary sm" onClick={() => window.location.reload()}>
            {getLocalizedString("okay", "Okay")}
          </QIButton>
        </QIModalFooter>
      </QIModal>
    </>
  );
};
