import { createApi } from "@reduxjs/toolkit/query/react";
import {
  createMutation,
  deleteMutation,
  updateMutation,
  getCollectionQuery,
  getQuery,
  baseQueryAuth,
  basePluginQueryAuth,
} from "../../reduxStore/helper";

export const managementPortalApi = createApi({
  reducerPath: "managementPortalApi",
  name: "managementPortalApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryAuth(process.env.REACT_APP_CORE_API_URL),
  endpoints: (builder) => ({
    getDevices: getCollectionQuery(builder, "devices"),
    getDevice: getQuery(builder, "devices", "id"),
    getNewDevice: getQuery(builder, "devices/new_device"),
    createDevice: createMutation(builder, "devices"),
    updateDevice: updateMutation(builder, "devices"),
    deleteDevice: deleteMutation(builder, "devices"),
    getNotificationsCount: getQuery(builder, "notifications/unread_notification_count"),

    getDMans: getCollectionQuery(builder, "device_manufacturers"),

    getDModels: getCollectionQuery(builder, "device_models"),
    getDModel: getQuery(builder, "device_models", "id"),
    updateDModel: updateMutation(builder, "device_models"),

    getApps: getCollectionQuery(builder, "apps"),

    getGeocoders: getCollectionQuery(builder, "geocoders"),

    getDriver: getQuery(builder, "drivers", "id"),
    getDrivers: getCollectionQuery(builder, "drivers"),
    createDriver: createMutation(builder, "drivers"),
    updateDriver: updateMutation(builder, "drivers"),
    deleteDriver: deleteMutation(builder, "drivers"),

    getPlugin: getQuery(builder, "plugins", "id"),
    getPlugins: getCollectionQuery(builder, "plugins"),
    createPlugin: createMutation(builder, "plugins"),
    updatePlugin: updateMutation(builder, "plugins"),
    deletePlugin: deleteMutation(builder, "plugins"),

    getUser: getQuery(builder, "users", "id"),
    getUsers: getCollectionQuery(builder, "users"),
    createUser: createMutation(builder, "users"),
    updateUser: updateMutation(builder, "users"),
    deleteUser: deleteMutation(builder, "users"),
    updateUserProfile: updateMutation(builder, "profiles"),

    getVariableType: getQuery(builder, "variable_types"),
    getVariableTypes: getCollectionQuery(builder, "variable_types"),
    deleteVariableType: deleteMutation(builder, "variable_types"),

    getRoles: getCollectionQuery(builder, "client_app_roles"),
    getRole: getQuery(builder, "client_app_roles", "id"),
    createRole: createMutation(builder, "client_app_roles"),
    updateRole: updateMutation(builder, "client_app_roles", "id"),
    deleteRole: deleteMutation(builder, "client_app_roles"),

    getAppFeatures: builder.query({
      query: (queryParams) => ({
        url: `app_features`,
        method: "get",
        params: queryParams,
      }),
      transformResponse: (response) => {
        const groupedData = {};
        const { data } = response;

        for (let i = 0; i < data.length; i++) {
          const { name, id, label_name } = data[i];
          if (groupedData[name]) {
            groupedData[name][label_name] = id;
          } else {
            groupedData[name] = { [label_name]: id };
          }
        }
        return groupedData;
      },
    }),

    getVClasses: getCollectionQuery(builder, "vehicle_classes"),
    getVClass: getQuery(builder, "vehicle_classes", "id"),
    createVClass: createMutation(builder, "vehicle_classes"),
    updateVClass: updateMutation(builder, "vehicle_classes"),
    deleteVClass: deleteMutation(builder, "vehicle_classes"),

    getVTypes: getCollectionQuery(builder, "vehicle_types"),
    getVType: getQuery(builder, "vehicle_types", "id"),
    createVType: createMutation(builder, "vehicle_types"),
    updateVType: updateMutation(builder, "vehicle_types"),
    deleteVType: deleteMutation(builder, "vehicle_types"),

    getVMans: getCollectionQuery(builder, "vehicle_manufacturers"),
    getVMan: getQuery(builder, "vehicle_manufacturers", "id"),
    createVMan: createMutation(builder, "vehicle_manufacturers"),
    updateVMan: updateMutation(builder, "vehicle_manufacturers"),
    deleteVMan: deleteMutation(builder, "vehicle_manufacturers"),

    getVManModels: getCollectionQuery(builder, "vehicle_manufacturer_models"),
    getVManModel: getQuery(builder, "vehicle_manufacturer_models", "id"),
    createVManModel: createMutation(builder, "vehicle_manufacturer_models"),
    updateVManModel: updateMutation(builder, "vehicle_manufacturer_models"),
    deleteVManModel: deleteMutation(builder, "vehicle_manufacturer_models"),

    getVModels: getCollectionQuery(builder, "vehicle_models"),
    getVModel: getQuery(builder, "vehicle_models", "id"),
    createVModel: createMutation(builder, "vehicle_models"),
    updateVModel: updateMutation(builder, "vehicle_models"),
    deleteVModel: deleteMutation(builder, "vehicle_models"),

    getVManModelVariants: getCollectionQuery(builder, "vehicle_manufacturer_model_variants"),
    getVManModelVariant: getQuery(builder, "vehicle_manufacturer_model_variants", "id"),
    createVManModelVariant: createMutation(builder, "vehicle_manufacturer_model_variants"),
    updateVManModelVariant: updateMutation(builder, "vehicle_manufacturer_model_variants"),
    deleteVManModelVariant: deleteMutation(builder, "vehicle_manufacturer_model_variants"),

    getVehicles: getCollectionQuery(builder, "vehicles"),
    getVehicle: getQuery(builder, "vehicles", "id"),
    createVehicle: createMutation(builder, "vehicles"),
    updateVehicle: updateMutation(builder, "vehicles"),
    deleteVehicle: deleteMutation(builder, "vehicles"),

    getDeviceGroups: getCollectionQuery(builder, "device_groups"),
    getDeviceGroup: getQuery(builder, "device_groups", "id"),
    createDeviceGroup: createMutation(builder, "device_groups"),
    updateDeviceGroup: updateMutation(builder, "device_groups"),
    deleteDeviceGroup: deleteMutation(builder, "device_groups"),

    getUserGroups: getCollectionQuery(builder, "user_groups"),
    getUserGroup: getQuery(builder, "user_groups", "id"),
    createUserGroup: createMutation(builder, "user_groups"),
    updateUserGroup: updateMutation(builder, "user_groups"),
    deleteUserGroup: deleteMutation(builder, "user_groups"),

    getVehicleGroups: getCollectionQuery(builder, "vehicle_groups"),
    getVehicleGroup: getQuery(builder, "vehicle_groups", "id"),
    createVehicleGroup: createMutation(builder, "vehicle_groups"),
    updateVehicleGroup: updateMutation(builder, "vehicle_groups"),
    deleteVehicleGroup: deleteMutation(builder, "vehicle_groups"),

    getDriverGroups: getCollectionQuery(builder, "driver_groups"),
    getDriverGroup: getQuery(builder, "driver_groups", "id"),
    createDriverGroup: createMutation(builder, "driver_groups"),
    updateDriverGroup: updateMutation(builder, "driver_groups"),
    deleteDriverGroup: deleteMutation(builder, "driver_groups"),

    getEventConfigurations: getCollectionQuery(builder, "event_configurations"),
    getDefaultEventConfigurations: getCollectionQuery(
      builder,
      "device_models/default_model_event_configurations"
    ),
    getEventConfiguration: getQuery(builder, "event_configurations", "id"),
    createEventConfiguration: createMutation(builder, "event_configurations"),
    updateEventConfiguration: updateMutation(builder, "event_configurations"),
    deleteEventConfiguration: deleteMutation(builder, "event_configurations"),

    getEventConfigurationCustomCategories: getCollectionQuery(
      builder,
      "event_configurations/custom/category"
    ),

    getTripConfigurations: getCollectionQuery(builder, "event_configurations/trip_configurations"),

    getEventConfigurationPlans: getCollectionQuery(builder, "event_handling_plans"),
    getEventConfigurationPlan: getQuery(builder, "event_handling_plans", "id"),
    createEventConfigurationPlan: createMutation(builder, "event_handling_plans"),
    updateEventConfigurationPlan: updateMutation(builder, "event_handling_plans"),
    deleteEventConfigurationPlan: deleteMutation(builder, "event_handling_plans"),

    getDashBoard: getCollectionQuery(builder, "dashboard"),
    getLanguages: getCollectionQuery(builder, "locale"),
    getTranslation: getQuery(builder, "locale/management_portal", "id"),
    getNotifications: getCollectionQuery(builder, "notifications"),

    getLocalizations: getCollectionQuery(builder, "localizations"),

    createSimCard: createMutation(builder, "sim_cards"),
    getMSPs: getCollectionQuery(builder, "mobile_service_providers"),
    getSimCards: getCollectionQuery(builder, "sim_cards"),
    deleteSimCard: deleteMutation(builder, "sim_cards"),
    getSimCard: getQuery(builder, "sim_cards", "id"),
    updateSimCard: updateMutation(builder, "sim_cards"),

    createVehicleGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    updateVehicleGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    createDeviceGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    updateDeviceGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    createDriverGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    updateDriverGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    createUserGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    updateUserGroupCsv: builder.mutation({
      query: (queryParams) => {
        return {
          url: `csv_uploads`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    getGeofences: getCollectionQuery(builder, "geofences"),
    getGeofence: getQuery(builder, "geofences", "id"),
    createGeofence: createMutation(builder, "geofences"),
    updateGeofence: updateMutation(builder, "geofences"),
    deleteGeofence: deleteMutation(builder, "geofences"),

    getReportConfigurations: getCollectionQuery(builder, "report_configurations"),
    createReportConfiguration: createMutation(builder, "report_configurations"),
    updateReportConfiguration: updateMutation(builder, "report_configurations"),
    getReportConfiguration: getQuery(builder, "report_configurations", "id"),

    getDeviceModelVariables: getCollectionQuery(builder, "device_model_variables"),
    getVariables: getCollectionQuery(builder, "variables"),

    createDeviceCommand: builder.mutation({
      query: (queryParams) => {
        return {
          url: `devices/${queryParams.device_id}/device_commands`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    getDeviceCommands: builder.query({
      query: (queryParams) => ({
        url: `devices/${queryParams.device_id}/device_commands`,
        method: "get",
        params: queryParams.params,
      }),
      keepUnusedDataFor: 2,
    }),

    resetPassword: builder.mutation({
      query: (queryParams) => {
        return {
          url: `users/${queryParams.id}/update_password`,
          method: "put",
          body: queryParams.formData,
        };
      },
    }),
    getAuditableResourceType: getCollectionQuery(builder, "auditable_events/resource_types"),
    getAuditableEvent: getQuery(builder, "auditable_events", "id"),

    getBackendDropdown: getCollectionQuery(builder, "backends/dropdown"),
    getBackend: getQuery(builder, "backends", "id"),
    getBackends: getCollectionQuery(builder, "backends"),
    createBackend: createMutation(builder, "backends"),
    updateBackend: updateMutation(builder, "backends"),
    deleteBackend: deleteMutation(builder, "backends"),
    getBackendTypes: getCollectionQuery(builder, "backends/transport_types"),

    getGeolocations: getCollectionQuery(builder, "geolocation_plans"),

    getVariableMappings: getCollectionQuery(builder, "variable_mappings"),

    getClients: getCollectionQuery(builder, "reseller_clients"),
    getClient: getQuery(builder, "reseller_clients", "id"),
    createClient: createMutation(builder, "reseller_clients"),
    updateClient: updateMutation(builder, "reseller_clients"),

    getClientConfig: builder.query({
      query: (queryParams) => ({
        url: `clients/get_client_logo`,
        method: "get",
      }),
    }),

    updateClientConfig: builder.mutation({
      query: (queryParams) => {
        return {
          url: `clients/update_client_logo`,
          method: "put",
          body: queryParams.formData,
        };
      },
    }),

    getClientAppConfig: builder.query({
      query: (queryParams) => ({
        url: `clients/get_app_logo?app_id=${queryParams.appId}`,
        method: "get",
      }),
    }),

    updateNotificationRead: builder.mutation({
      query: (queryParams) => {
        return {
          url: `notifications/${queryParams.id}/read_notification`,
          method: "put",
          body: queryParams["data-raw"],
        };
      },
    }),

    updateClientAppConfig: builder.mutation({
      query: (queryParams) => {
        return {
          url: `clients/update_app_logo`,
          method: "put",
          body: queryParams.formData,
        };
      },
    }),

    //Command sending
    getCommands: getCollectionQuery(builder, "commands"),

    //Reports Generation
    getReport: getCollectionQuery(builder, "reports"),
    createReport: createMutation(builder, "reports"),
    deleteReport: deleteMutation(builder, "reports"),

    //Api Keys/Token Invoke and Revoke
    getApiKeys: getCollectionQuery(builder, "api_keys"),
    getApiKey: getQuery(builder, "api_keys", "id"),
    updateApiKey: updateMutation(builder, "api_keys"),
    createApiKey: createMutation(builder, "api_keys"),
    deleteApiKey: deleteMutation(builder, "api_keys"),

    //Timezone Api
    getTimezone: getQuery(builder, "time_zones"),
  }),
});

export const pluginApi = createApi({
  reducerPath: 'pluginApi',
  baseQuery: basePluginQueryAuth, 
  tagTypes: ['Plugin', 'Container', 'TrackingContainer'],
  endpoints: (builder) => ({
      getPlugins2: builder.query({
          query: () => '/plugins',  
          providesTags: ['Plugin']
      }),
      // getPlugin: builder.query({
      //     query: (id) => `/api/plugins/${id}`,  
      //     providesTags: ['Plugin']
      // }),
      createPlugin2: builder.mutation({
          query: (pluginData) => ({
              url: '/plugins',  
              method: 'POST',
              body: pluginData
          }),
          invalidatesTags: ['Plugin']
      }),
      // updatePlugin2: builder.mutation({
      //     query: ({ id, formData }) => ({
      //         url: `/plugins/${id}`,  
      //         method: 'PUT',
      //         body: formData
      //     }),
      //     invalidatesTags: ['Plugin']
      // }),

      updatePlugin2: builder.mutation({
        query: (queryParams) => {
            // Log the formData to see what is being sent in the request
            console.log('Sending formData:', queryParams);
    
            // Ensure formData contains the 'id' and other required fields
            return {
                url: `/plugins/${queryParams.id}`,  
                method: 'PUT',
                body: queryParams.formData
            };
        },
        invalidatesTags: ['Plugin']
      }),

      getContainers: builder.query({
        query: () => '/containers',  
          providesTags: ['Container']
      }),

      createContainer: builder.mutation({
        query: (queryParams) => ({
            url: '/containers',  
            method: 'POST',
            body: queryParams.formData
        }),
        invalidatesTags: ['Container']
      }),

      updateContainer: builder.mutation({
        query: (queryParams) => {
              return {
                url: `/containers/${queryParams.id}`,  
                method: 'PUT',
                body: queryParams.formData
            };
        },
        invalidatesTags: ['Container']
      }),

      // deletePlugin: builder.mutation({
      //     query: (id) => ({
      //         url: `/api/plugins/${id}`,  // Corrected to include /api
      //         method: 'DELETE'
      //     }),
      //     invalidatesTags: ['Plugin']
      // }),
  }),
});


export const internalService = createApi({
  reducerPath: "internalApi",
  name: "internalApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryAuth(process.env.REACT_APP_CORE_API_URL),
  endpoints: (builder) => ({
    getAuditableEvents: getCollectionQuery(builder, "auditable_events"),
  }),
});

export const { useGetAuditableEventsQuery } = internalService;

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useGetNewDeviceQuery,
  useDeleteDeviceMutation,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useGetNotificationsCountQuery,
  useUpdateNotificationReadMutation,
  useGetDMansQuery,

  useGetLocalizationsQuery,

  useGetDModelsQuery,
  useGetDModelQuery,
  useUpdateDModelMutation,

  useGetAppsQuery,

  useGetGeocodersQuery,

  useGetDriversQuery,
  useGetDriverQuery,
  useCreateDriverMutation,
  useUpdateDriverMutation,
  useDeleteDriverMutation,

  useGetPluginsQuery,
  useGetPluginQuery,
  useCreatePluginMutation,
  useUpdatePluginMutation,
  useDeletePluginMutation,

  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateUserProfileMutation,

  useGetVariableTypeQuery,
  useGetVariableTypesQuery,
  useUpdateVariableTypeMutation,
  useCreateVariableTypeMutation,
  useDeleteVariableTypeMutation,

  useGetRolesQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useCreateRoleMutation,

  useGetBackendQuery,
  useGetBackendsQuery,
  useCreateBackendMutation,
  useDeleteBackendMutation,
  useUpdateBackendMutation,
  useGetBackendTypesQuery,
  useGetBackendDropdownQuery,

  useGetAppFeaturesQuery,

  useGetVMansQuery,
  useGetVManQuery,
  useCreateVManMutation,
  useUpdateVManMutation,
  useDeleteVManMutation,

  useGetVTypesQuery,
  useGetVTypeQuery,
  useCreateVTypeMutation,
  useUpdateVTypeMutation,
  useDeleteVTypeMutation,

  useGetVClassesQuery,
  useGetVClassQuery,
  useCreateVClassMutation,
  useUpdateVClassMutation,
  useDeleteVClassMutation,

  useGetVModelsQuery,
  useGetVModelQuery,
  useCreateVModelMutation,
  useUpdateVModelMutation,
  useDeleteVModelMutation,

  useGetVManModelsQuery,
  useGetVManModelQuery,
  useCreateVManModelMutation,
  useUpdateVManModelMutation,
  useDeleteVManModelMutation,

  useGetVManModelVariantsQuery,
  useGetVManModelVariantQuery,
  useCreateVManModelVariantMutation,
  useUpdateVManModelVariantMutation,
  useDeleteVManModelVariantMutation,

  useGetVehiclesQuery,
  useGetVehicleQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,

  useGetDashBoardQuery,

  useGetTranslationQuery,
  useGetLanguagesQuery,
  useGetNotificationsQuery,

  useCreateDeviceCommandMutation,
  useGetDeviceCommandsQuery,

  useResetPasswordMutation,

  useGetAuditableEventQuery,
  useGetAuditableResourceTypeQuery,

  useGetDeviceGroupsQuery,
  useCreateDeviceGroupMutation,
  useUpdateDeviceGroupMutation,
  useGetDeviceGroupQuery,
  useDeleteDeviceGroupMutation,
  useCreateDeviceGroupCsvMutation,
  useUpdateDeviceGroupCsvMutation,

  useGetUserGroupsQuery,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useGetUserGroupQuery,
  useDeleteUserGroupMutation,
  useCreateUserGroupCsvMutation,
  useUpdateUserGroupCsvMutation,

  useGetVehicleGroupsQuery,
  useCreateVehicleGroupMutation,
  useUpdateVehicleGroupMutation,
  useGetVehicleGroupQuery,
  useDeleteVehicleGroupMutation,
  useCreateVehicleGroupCsvMutation,
  useUpdateVehicleGroupCsvMutation,

  useGetDriverGroupsQuery,
  useCreateDriverGroupMutation,
  useUpdateDriverGroupMutation,
  useGetDriverGroupQuery,
  useDeleteDriverGroupMutation,
  useCreateDriverGroupCsvMutation,
  useUpdateDriverGroupCsvMutation,

  useGetEventConfigurationsQuery,
  useGetDefaultEventConfigurationsQuery,
  useGetEventConfigurationQuery,
  useCreateEventConfigurationMutation,
  useUpdateEventConfigurationMutation,
  useDeleteEventConfigurationMutation,

  useGetEventConfigurationCustomCategoriesQuery,

  useGetTripConfigurationsQuery,

  useGetEventConfigurationPlansQuery,
  useGetEventConfigurationPlanQuery,
  useCreateEventConfigurationPlanMutation,
  useUpdateEventConfigurationPlanMutation,
  useDeleteEventConfigurationPlanMutation,

  useGetClientConfigQuery,
  useUpdateClientConfigMutation,

  useGetClientAppConfigQuery,
  useUpdateClientAppConfigMutation,

  useGetGeofencesQuery,
  useGetGeofenceQuery,
  useCreateGeofenceMutation,
  useDeleteGeofenceMutation,
  useUpdateGeofenceMutation,
  useGetVariableMappingsQuery,
  useGetGeolocationsQuery,

  useGetReportConfigurationsQuery,
  useCreateReportConfigurationMutation,
  useUpdateReportConfigurationMutation,
  useGetReportConfigurationQuery,
  useGetDeviceModelVariablesQuery,
  useGetVariablesQuery,

  useGetReportQuery,
  useCreateReportMutation,
  useDeleteReportMutation,

  useGetApiKeysQuery,
  useGetApiKeyQuery,
  useCreateApiKeyMutation,
  useUpdateApiKeyMutation,
  useDeleteApiKeyMutation,

  useCreateSimCardMutation,
  useGetMSPsQuery,
  useGetSimCardsQuery,
  useDeleteSimCardMutation,
  useGetSimCardQuery,
  useUpdateSimCardMutation,

  useGetTimezoneQuery,
  useGetClientsQuery,
  useGetClientQuery,
  useUpdateClientMutation,
  useCreateClientMutation,
  useGetCommandsQuery,
} = managementPortalApi;

export const {
  useGetPlugins2Query,
  useCreatePlugin2Mutation,
  useUpdatePlugin2Mutation,
  useGetContainersQuery,
  useCreateContainerMutation,
  useUpdateContainerMutation,
} = pluginApi;
