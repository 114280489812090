import { useEffect, useState, useMemo } from "react";
import qiLogo from "./qi-logo.png";
import { useSelector } from "react-redux";
import { removeConsecutiveDuplicates } from "../../containers/Tracking/utils";
import { FMDPStorage } from "../../shared/helper";

export const QIFlashScreen = ({ flashScreenStatus, currentAppId, children }) => {
  const [flashTimer, setFlashTimer] = useState(true);
  const [clientState, setClientState] = useState(null);

  let lastVisitedApps = useSelector((state) => state.common.lastVisitedApp);
  lastVisitedApps = removeConsecutiveDuplicates(lastVisitedApps);

  const [currentClientApps, setCurrentClientApps] = useState([]);

  useEffect(() => {
    const apps = FMDPStorage.get("current-user")?.client_apps || [];
    setCurrentClientApps(apps);

    setTimeout(() => {
      setFlashTimer(false);
    }, 2000);
  }, []);

  const findClient = (appId) => {
    const clientId = currentClientApps.find((item) => item?.id === appId);
    return clientId?.client?.id;
  };

  const isClientStateSame = useMemo(() => {
    const lastClient = findClient(lastVisitedApps[lastVisitedApps?.length - 1]);
    const secondLastClient = findClient(lastVisitedApps[lastVisitedApps?.length - 2]);
    return lastClient === secondLastClient;
  }, [lastVisitedApps, currentClientApps]);

  useEffect(() => {
    setClientState(isClientStateSame ? "same" : "diff");
  }, [isClientStateSame]);

  const flashMessage = () => (
    <div className="qi-flash-screen">
      <div className="logo">
        <img src={qiLogo} alt="qi-logo" />
      </div>
    </div>
  );

  return (
    <div>
      {flashTimer && clientState === "diff" && flashScreenStatus ? flashMessage() : children}
    </div>
  );
};
