import React, { useState } from "react";
import { Profile } from "./Profile/Profile";
import { ClientConfig } from "./Portal/ClientConfig";
import { useGetAppsQuery } from "../services";
import { ApiListContainer } from "./ApiKeys/ApiList";
import { MPSharedHeader } from "../Shared/";
import {getLocalizedString} from "../../../shared/translation";

export const CustomizationContainer = () => {
  const { data } = useGetAppsQuery();
  const [activeTab, setActiveTab] = useState("portal-customization"); // Default active tab is 'client'

  return (
    <>
      <MPSharedHeader
        heading={getLocalizedString("settings", "Settings")}
        resourceName="Client"
        groupName="client_config"
      />

      {activeTab === "portal-customization" && (
        <section className="customization">
          <>
            <ClientConfig />
            {data?.data.map((app) => (
              <Profile appName={app.name} appId={app.id} key={app.id} />
            ))}
          </>
        </section>
      )}
      {activeTab === "api-keys" && <ApiListContainer />}
    </>
  );
};
