import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "./ErrorBoundary";
import { loader } from "../../assets";

const LazyPluginComponent = lazy(() => import("./PluginComponent"));

window.React = React;
window.ReactDOM = ReactDOM;

export const PluginContainer = ({ selectedPlugin, isSuccess, data }) => {
  return (
    <div id="plugin-target">
      <ErrorBoundary>
        <Suspense fallback={<img src={loader} width="40px" />}>
            {isSuccess && data?.total_count === 0 ? "No plugins are configured": <LazyPluginComponent plugin={selectedPlugin} />}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
