import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TAddContainerForm } from "./form";
import { useGetContainersQuery } from "../../../ManagementPortal/services";

export const TAddCustomPluginContainer = () => {
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();

  //const { data: newContainers } = useGetContainersQuery();

  const handleClose = () => {
    setShowForm(false);
    navigate("/tracking/devices");
  };

  return <>{showForm && <TAddContainerForm onClose={handleClose} />}</>;
};
