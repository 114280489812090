import { QIExpand, QICustomDateRangePicker, QITable, QISpinner } from "../../../components";
import { TrackingTable } from "./index";
import Scrollbars from "react-custom-scrollbars";
import { PaginationFooter, NoData } from "../../Shared";
import { useEffect, useState } from "react";
import { FMDPStorage } from "../../../shared/helper";

export const MapViewFlyOut = ({
  data,
  resetCount,
  selectedRange,
  setSelectedRange,
  listType,
  setResetCount,
  setMapExpand,
  simpleSearch,
  setSimpleSearch,
  isSuccess = true,
  isLoading,
  selectedPage,
  pagination,
  children,
  search_by_message = "",
  title,
}) => {
  const currentUser = FMDPStorage?.get("current-user")?.client_apps;
  const currentClientId = FMDPStorage?.get("selected-client-id");
  const [configJson, setConfigJson] = useState({});
  const [isAlternativeUI, setIsAlternativeUI] = useState(false);

  // To get the custom config for handling the alternative UI
  useEffect(() => {
    if (currentClientId && currentUser) {
      currentUser?.map((clientApp) => {
        if (clientApp?.client?.id === currentClientId) {
          setConfigJson(clientApp?.client?.config_json);
        }
      });
    }
  }, [currentClientId]);

  // Handling alternative UI from the custom json otherwise show the default UI
  useEffect(() => {
    if (configJson) {
      try {
        if (JSON?.parse(configJson)?.tracking?.web?.tracking_layout === "alternative") {
          setIsAlternativeUI(true);
        } else {
          setIsAlternativeUI(false);
        }
      } catch {
        setIsAlternativeUI(false);
      }
    }
  }, [configJson]);

  return (
    <>
      <QIExpand
        simpleSearch={simpleSearch}
        setSimpleSearch={setSimpleSearch}
        simpleSearchPlaceholder={search_by_message}
        setMapExpand={setMapExpand}
        title={title}
        alternativeUI={isAlternativeUI}
      >
        {selectedPage !== "Vehicles" && (
          <QICustomDateRangePicker
            resetCount={resetCount}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            setResetCount={setResetCount}
          />
        )}
        {isLoading && (
          <div className="no-data">
            <QISpinner size="50px" />
          </div>
        )}
        {data?.length > 0 ? (
          <ul className={`${listType.toLowerCase()}-list card-view-list`}>{children}</ul>
        ) : (
          !isLoading && <NoData />
        )}
        {data?.length > 0 && isSuccess && pagination && (
          <PaginationFooter pagination={pagination} />
        )}
      </QIExpand>
    </>
  );
};
