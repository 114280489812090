import { useParams } from "react-router-dom";
import { useGetContainersQuery, useGetPluginsQuery } from "../services";
import { MPSharedHeader } from "../Shared";
import { getLocalizedString } from "../../../shared/translation";
import { useState } from "react";
import { usePagination } from "../../../hooks";
import { PluginContainer } from "../../Plugins";
import { EditCustomPluginContainer } from "./EditCustomPluginContainer";

export const MPRenderFirstPluginContainer = () => {
  let params = useParams();
  const [showForm, setShowForm] = useState(true);
  const [idToEdit, setIdToEdit] = useState(null);

  //const { data: newContainers, isFetching, error } = useGetContainersQuery();

  // let selectedContainer = newContainers.find((container) => container.path === params.path);

  /* if (!selectedContainer && !isFetching) {
    return <div>{getLocalizedString("No container found or invalid path", "No container found or invalid path")}</div>;
  } */

  const onCreate = () => {
    setShowForm(true);
  };

  const onEdit = (container) => {
    setIdToEdit(container.id);
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const { page, setPage, perPage, pagination } = usePagination();

  const { data = { data: [], total_count: 0 }, isSuccess } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  let selectedPlugin;

  selectedPlugin = data.data.find((plugin) => plugin.id === selectedContainer.plugins[0]);

  if (!selectedPlugin && isSuccess) {
    return (
      <div>
        {getLocalizedString(
          "No plugin found for the selected container or invalid plugin path.",
          "No plugin found for the selected container or invalid plugin path."
        )}
      </div>
    );
  }

  const strippedName = selectedContainer?.name.replace(/\s+/g, "");

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName={strippedName}
          resourceName={`${selectedContainer?.name}`}
          createNewHandler={onCreate}
          heading={getLocalizedString(`${selectedContainer?.name}`, `${selectedContainer?.name}`)}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          selectedContainer={selectedContainer}
          onEdit={onEdit}
        />
        {showForm && idToEdit && (
          <EditCustomPluginContainer
            idToEdit={idToEdit}
            handleClose={handleClose}
            showForm={showForm}
          />
        )}
        <PluginContainer selectedPlugin={selectedPlugin} isSuccess={isSuccess} data={data} />
      </article>
    </>
  );
};
