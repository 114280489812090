import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import {
  useCreateReportConfigurationMutation,
  useUpdateReportConfigurationMutation,
  useGetReportConfigurationQuery,
  useGetVariablesQuery,
} from "../services";
import { useDropDownSearch, useForm, useStaticDropDownSearch } from "../../../hooks";
import { reportConfigurationsFormSchema } from "../validations";
import { reportTypes } from "../../../shared/reportTypes";
import { getLocalizedString } from "../../../shared/translation";
import { VariableDropdown } from "./VariableDropdown";

export const ReportConfigurationsForm = ({ idToEdit = null, onClose }) => {
  const { data: variables = {} } = useGetVariablesQuery({
    per_page: 2000,
    order_by: "variables.display_name",
    order_dir: "asc",
    classification_type: ["tracking_data_device", "tracking_data_custom", "tracking_data_api"],
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    report_type: "trackpoint",
    variable_ids: [],
  });

  const [message, setMessage] = useState("");
  const [selectedVariableId, setSelectedVariableId] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredVariablesData, setFilteredVariablesData] = useState([]);
  const [expandFrom, setExpandForm] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
    setSearchTerm(""); // Clear search term when dropdown is closed
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: reportConfigurationsFormSchema,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const { data = {} } = useGetReportConfigurationQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  useEffect(() => {
    if (formik?.values?.report_type === "event") {
      setMessage(
        getLocalizedString("fields_unavailable_for_events", "Fields unavailable for Events")
      );
    } else if (formik?.values?.report_type === "trip") {
      setMessage(
        getLocalizedString("fields_unavailable_for_trips", "Fields unavailable for Trips")
      );
    } else {
      setMessage("");
    }
  }, [formik?.values?.report_type]);

  const { create: createReportConfiguration, update: updateReportConfiguration } = useForm({
    createMutation: useCreateReportConfigurationMutation,
    updateMutation: useUpdateReportConfigurationMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formData) => {
    let variableIds = [];
    const tempFormData = { ...formData, variable_ids: variableIds };

    formData?.variable_ids?.map((variable) => {
      if (variable?.id) {
        variableIds?.push(variable?.id);
      } else {
        variableIds?.push(variable);
      }
    });

    if (idToEdit) {
      updateReportConfiguration({ id: idToEdit, formData: tempFormData });
    } else {
      createReportConfiguration({ formData: tempFormData });
    }
  };

  const [mappingValues, setmappingValues] = useState({
    id: "",
    section_name: "",
    unit: "",
  });

  const [reorderedMappingData, setReorderedMappingData] = useState([]);
  const [displayNameMap, setDisplayNameMap] = useState({});
  const mapColumnNames = ["id"];

  const formikMapping = useFormik({
    initialValues: mappingValues,
    enableReinitialize: true,
  });

  const [headers, setHeaders] = useState([
    {
      label: "Display Name",
      key: "variable_ids",
      error: "",
      dataType: "",
    },
    {
      label: "Section Name",
      key: "section_name",
      error: "",
      dataType: "",
    },
    {
      label: "Unit",
      key: "unit",
      error: "",
      dataType: "",
    },
    {
      className: "action",
    },
  ]);

  const handleChange = (name, value) => {
    setSelectedVariableId(value);
    const parsedValue = JSON?.parse(value);
    formikMapping.setFieldValue(name, parsedValue?.id);
    formikMapping.setFieldValue("section_name", parsedValue?.section_name);
    formikMapping.setFieldValue("unit", parsedValue?.unit_name);
    closeDropdown();
  };

  const getVariableDetails = (id, key) => {
    let value;
    variables?.data?.map((variable) => {
      if (variable?.id === id) {
        value = variable[key];
      }
    });
    return value;
  };

  useEffect(() => {
    if (idToEdit && variables && data) {
      const updatedFormData = mergeObjects(formik.values, data);
      setInitialValues(updatedFormData);

      const tempData = updatedFormData?.variable_ids?.map((item) => ({
        id: item,
        section_name: getVariableDetails(item, "section_name"),
        unit: getVariableDetails(item, "unit_name"),
      }));
      setReorderedMappingData(tempData);
    }
  }, [data, variables]);

  const handleOnCancel = () => {
    formikMapping.setFieldValue("id", "");
    formikMapping.setFieldValue("section_name", "");
    formikMapping.setFieldValue("unit", "");
  };

  const handleSubmit = (values) => {
    formik.setFieldValue("variable_ids", values);
    formikMapping.setFieldValue("unit", "");
    formikMapping.setFieldValue("section_name", "");
  };

  useEffect(() => {
    if (variables) {
      let tempObj = {};
      variables?.data?.forEach((data) => {
        tempObj[data.id] = data.display_name;
      });
      setDisplayNameMap({ id: tempObj });
    }
  }, [variables]);

  useEffect(() => {
    const filteredData = variables?.data?.filter((data) =>
      data?.display_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (searchTerm) {
      setFilteredVariablesData(filteredData);
    } else {
      setFilteredVariablesData(variables?.data);
    }
  }, [searchTerm, variables?.data]);

  const validateAndSetError = (fieldName, errorValue, updatedHeaders) => {
    //To update the error message for every mapping data
    const tempHeaders = updatedHeaders.map((header) => {
      if (header.key === fieldName) {
        return {
          ...header,
          error: errorValue,
        };
      }
      return header;
    });

    return tempHeaders;
  };

  const validateReport = (objectItem, allData) => {
    // Adding validations for reports
    let updatedHeaders = structuredClone(headers);
    let errorPresent = false;
    let required = false;

    if (!objectItem?.id) {
      updatedHeaders = validateAndSetError("variable_ids", "Required", updatedHeaders);
      required = errorPresent = true;
    } else {
      updatedHeaders = validateAndSetError("variable_ids", "", updatedHeaders);
    }

    if (!errorPresent && !required) {
      allData?.forEach((datum) => {
        if (datum?.id === objectItem?.id) {
          updatedHeaders = validateAndSetError("variable_ids", "Already exists", updatedHeaders);
          errorPresent = true;
        } else {
          if (!errorPresent)
            updatedHeaders = validateAndSetError("variable_ids", "", updatedHeaders);
        }
      });
    }

    setHeaders(updatedHeaders);
    if (!errorPresent) {
      formikMapping.setValues(mappingValues);
    }

    return errorPresent;
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("report", "Report")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QICustomSelect
        label={getLocalizedString("type", "Type")}
        {...formik.getFieldProps("report_type")}
        onChange={(value) => {
          formik.setFieldValue("report_type", value);
        }}
        error={formik.touched.report_type && formik.errors.report_type}
        disabled={true}
        labelClassName="type"
      >
        <>
          {reportTypes?.map((value, index) => (
            <li value={value.id} key={index}>
              {value.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      {message && <p className="configurations-error">{message}</p>}

      {(formik?.values?.report_type === "trackpoint" ||
        formik?.values?.report_type === "tracking") && (
        <div className="add-edit-report-type-list">
          <Components.QITableCustom
            handleSubmit={handleSubmit}
            headers={headers}
            objectItem={formikMapping.values}
            handleOnCancel={handleOnCancel}
            initialValues={reorderedMappingData}
            displayNameMap={displayNameMap}
            mapColumnNames={mapColumnNames}
            error={formik.touched.variable_ids && formik.errors.variable_ids}
            setSelectedVariableId={setSelectedVariableId}
            expandForm={expandFrom}
            validator={validateReport}
          >
            <div className="flex items-center displayname-section">
              {/* <div className="qi-select_wrapper">
                <div className="qi-select">
                  <div className="qi-select_text" onClick={toggleDropdown}>
                    {selectedVariableId
                      ? JSON.parse(selectedVariableId)?.display_name
                      : "Select Variable"}
                  </div>
                  {dropdownOpen && (
                    <div className="qi-select_dropdown">
                      <input
                        placeholder="Search..."
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="qi-select_search"
                      />
                      <ul className="qi-select_options">
                        {variables?.data
                          ?.filter((data) =>
                            data?.display_name.toLowerCase().includes(searchTerm.toLowerCase())
                          )
                          .map((data, index) => (
                            <li
                              className="qi-select_option"
                              key={index}
                              onClick={() => {
                                handleChange("id", JSON.stringify(data));
                                setDropdownOpen(false);
                                setSelectedVariableId(null);
                              }}
                            >
                              {data?.display_name} ({data?.section_name}
                              {data?.unit_name ? ` - ${data?.unit_name}` : ""})
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="text-red-600 small-error">{headers[0]?.error}</div>
              </div> */}
              {/* <VariableDropdown
                toggleDropdown={toggleDropdown}
                selectedVariableId={selectedVariableId}
                dropdownOpen={dropdownOpen}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                variables={variables}
                handleChange={handleChange}
                setDropdownOpen={setDropdownOpen}
                headers={headers}
              /> */}
              <div onClick={() => setExpandForm((prev) => !prev)} className="dispplayname">
                <Components.QICustomSelect
                  label={selectedVariableId ? JSON.parse(selectedVariableId)?.display_name : ""}
                  onChange={(value) => {
                    handleChange("id", value);
                  }}
                  onSearch={setSearchTerm}
                  setExpandForm={setExpandForm}
                >
                  {filteredVariablesData?.map((data, index) => {
                    return (
                      <li
                        className="qi-select_option"
                        key={index}
                        value={JSON.stringify(data)}
                        title={data?.display_name}
                      >
                        {data?.display_name} ({data?.section_name}
                        {data?.unit_name ? ` - ${data?.unit_name}` : ""})
                      </li>
                    );
                  })}
                </Components.QICustomSelect>
                <div className="text-red-600 small-error">{headers[0]?.error}</div>
              </div>
              <div className="qi-list-view_column">
                <input
                  className="w-34"
                  placeholder="Section Name"
                  disabled={true}
                  value={formikMapping?.values?.section_name}
                />
                <div className="text-red-600 small-error">{headers[1]?.error}</div>
              </div>
              <div className="qi-list-view_column">
                <input
                  className="w-34"
                  placeholder="Unit"
                  disabled={true}
                  value={formikMapping?.values?.unit}
                />
                <div className="text-red-600 small-error">{headers[2]?.error}</div>
              </div>
            </div>
          </Components.QITableCustom>
        </div>
      )}
    </FormContainer>
  );
};
