import React, { useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import {
  useCreateDeviceCommandMutation,
  useGetDeviceCommandsQuery,
  useGetDeviceQuery,
  useGetCommandsQuery,
} from "../services";
import * as Components from "../../../components";
import { Terminal } from "./terminal";
import { getLocalizedString } from "../../../shared/translation";
import { useDropDownSearch } from "../../../hooks";
import { commandSendingSchema } from "../validations";

const CommandStatus = ({ deviceId }) => {
  const { data: deviceCommands, refetch } = useGetDeviceCommandsQuery({
    device_id: deviceId,
    params: { order_by: "id", order_dir: "desc", page: 0, per_page: 20 },
  });

  const tableHeaders = [
    {
      label: getLocalizedString("id", "ID"),
      key: "id",
    },
    {
      label: getLocalizedString("status", "Status"),
      key: "status",
    },
    {
      label: getLocalizedString("type", "Type"),
      key: "command_type",
    },
    {
      label: getLocalizedString("mode", "Mode"),
      key: "delivery_mode",
    },
    {
      label: getLocalizedString("created_on", "Created On"),
      type: "component",
      component: ({ data }) => {
        let time = moment.unix(data.created_at).format("DD-MM-YYYY HH:mm:ss A");
        return <span title={time}>{time}</span>;
      },
    },
    {
      label: getLocalizedString("created_by", "Created By"),
      key: "created_by",
    },
    {
      label: getLocalizedString("delivered_on", "Delivered On"),
      type: "component",
      component: ({ data }) => {
        let time =
          data.delivered_on === null
            ? ""
            : moment.unix(data.delivered_on).format("DD-MM-YYYY HH:mm:ss A");
        return <span title={time}>{time}</span>;
      },
    },
    {
      label: getLocalizedString("command", "Command"),
      key: "command",
    },
  ];

  useEffect(() => {
    const caller = setInterval(() => {
      refetch();
    }, 2000);
    return () => {
      clearInterval(caller);
    };
  });

  return (
    <Components.QITable
      headers={tableHeaders}
      data={deviceCommands?.data || []}
      className="command-sending-status"
      maxHeight="calc(100vh - 560px)"
    />
  );
};

const Command = ({ deviceId, isLockable, isResettable, deviceCountryCode, deviceImei }) => {
  const [initialValues] = useState({
    delivery_mode: "",
    command_type: "",
    command: "",
    country_code: deviceCountryCode,
  });

  const { data: deviceData } = useGetDeviceQuery({ id: deviceId });

  // Get the commands list using Dropdown Search
  const {
    data: commands,
    setSearchKey: setCommandSearch,
    isSuccess: isCommandsSuccess,
  } = useDropDownSearch({
    useData: useGetCommandsQuery,
    simpleSearchKey: "name_cont",
    additionalParams: {
      per_page: 2000,
      order_by: "created_at",
      order_dir: "desc",
      device_id: deviceImei,
    },
  });

  const [sendCommand, { isSuccess }] = useCreateDeviceCommandMutation();

  const { data: deviceCommands, refetch } = useGetDeviceCommandsQuery({
    device_id: deviceId,
    params: { order_by: "id", order_dir: "desc", page: 0, per_page: 20 },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: commandSendingSchema,
    onSubmit: (values) => {
      // Restructure the formdata for the api call
      let newValues = {};
      if (values?.command_type !== "raw") {
        newValues["command"] = values?.command_type;
        newValues["command_type"] = "raw";
      } else {
        newValues["command"] = values?.command;
        newValues["command_type"] = values?.command_type;
      }
      newValues["delivery_mode"] = values?.delivery_mode;

      handleFormSubmit(newValues);
    },
  });

  const handleFormSubmit = (formData) => {
    sendCommand({
      device_id: deviceId,
      formData,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      formik.setValues(initialValues);
    }
  }, [isSuccess]);

  const commandTypes = [
    {
      label: "Configuration and Control (RAW)",
      key: "raw",
    },
  ];

  const getDeliveryModes = () => {
    let modes = ["TCP", "SMS", "Both"];

    return modes.map((mode) => (
      <li key={mode} value={mode.toLocaleLowerCase()}>
        {mode}
      </li>
    ));
  };

  // Set the delivery mode if command_type is selected
  const handleCommandTypeChange = (value) => {
    formik.setFieldValue("command_type", value);
    setCommandSearch("");

    if (value === "raw") {
      formik.setFieldValue("delivery_mode", "");
    } else {
      commands?.data?.map((command) => {
        if (command?.command_text === value) {
          formik.setFieldValue("delivery_mode", command?.mode);
        }
      });
    }
  };

  return (
    <form
      className="command-sending-form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="command-sending-form_filed-group">
        <Components.QICustomSelect
          label={getLocalizedString("command_type", "Command Type")}
          className="command-type"
          {...formik.getFieldProps("command_type")}
          onChange={(value) => handleCommandTypeChange(value)}
          onSearch={setCommandSearch}
          error={formik.touched.command_type && formik.errors.command_type}
        >
          {isCommandsSuccess &&
            commands.data.map((command) => (
              <li key={command.command_text} value={command.command_text}>
                {command.name}
              </li>
            ))}
          {commandTypes.map((mode) => (
            <li key={mode.key} value={mode.key}>
              {mode.label}
            </li>
          ))}
        </Components.QICustomSelect>

        <Components.QICustomSelect
          label={getLocalizedString("delivery_mode", "Delivery Mode")}
          className="delivery-mode"
          {...formik.getFieldProps("delivery_mode")}
          onChange={(value) => formik.setFieldValue("delivery_mode", value)}
          error={formik.touched.delivery_mode && formik.errors.delivery_mode}
        >
          {getDeliveryModes()}
        </Components.QICustomSelect>

        {formik?.values?.command_type === "raw" && (
          <Components.QIInput
            label={getLocalizedString("command", "Command")}
            className="raw-command"
            {...formik.getFieldProps("command")}
            error={formik.touched.command && formik.errors.command}
          />
        )}

        <Components.QIButton
          className="qi-btn  qi-btn qi-btn primary md"
          onClick={formik.handleSubmit}
        >
          {getLocalizedString("send", "Send")}
        </Components.QIButton>
      </div>
    </form>
  );
};

export const CommandSending = ({ deviceData = null, onClose }) => {
  const [showCommandStatus, setShowCommandStatus] = useState(false);

  return (
    <Components.QIModal show={true} onHide={onClose} backdrop={false} size="xl-large">
      <Components.QIModalHeader onHide={onClose}>
        <h5>IMEI({deviceData?.device_id})</h5>
      </Components.QIModalHeader>

      <Components.QIModalBody className="terminal">
        <div>
          {deviceData.device_type == "tracker" && (
            <section>
              <div className="flex">
                <span
                  className={`modal_tab ${!showCommandStatus && "active"}`}
                  onClick={() => setShowCommandStatus(false)}
                >
                  {getLocalizedString("command_sending", "Command Sending")}
                </span>

                <span
                  className={`modal_tab ${showCommandStatus && "active"}`}
                  onClick={() => setShowCommandStatus(true)}
                >
                  {getLocalizedString("command_status", "Command Status")}
                </span>
              </div>

              <div className="modal_tab-content">
                <div className={`${!showCommandStatus ? "hidden" : ""}`}>
                  <CommandStatus deviceId={deviceData?.id} />
                </div>
                <div className={`${showCommandStatus ? "hidden" : ""}`}>
                  <Command
                    deviceId={deviceData?.id}
                    deviceCountryCode={deviceData?.country_code}
                    isLockable={deviceData.is_lockable}
                    isResettable={deviceData.is_resettable}
                    deviceImei={deviceData?.device_id}
                  />
                  <div>
                    <h3 className="terminal-title">{getLocalizedString("terminal", "Terminal")}</h3>
                    <div>
                      <Terminal imei={deviceData?.device_id} show={true} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {deviceData.device_type != "tracker" && (
            <div>
              <h3 className="terminal-title">{getLocalizedString("terminal", "Terminal")}</h3>
              <div>
                <Terminal imei={deviceData?.device_id} show={true} />
              </div>
            </div>
          )}
        </div>
      </Components.QIModalBody>
    </Components.QIModal>
  );
};
