import React, { useState, useRef } from "react";
import { Icon, Style, Fill, Stroke, Text } from "ol/style.js";
import { useGetDevicesTrackingQuery, useGetVariablesDataQuery } from "../services";
import { useGetVariablesQuery } from "../../Admin/services";
import { VehicleDetailsWindowView } from "../Vehicles/VehicleDetailsWindowView";
import { VehicleMap } from "../Vehicles/VehicleMap";
import { VehicleTrack } from "../Vehicles/VehicleTrack";
import { DeviceList } from "./DeviceList";
import { Dnd, MinimisedTab, useDndContainer, WindowTab } from "../Dnd";
import { locationImg, locArrowImg } from "../assets/mapIcons";
import { useEffect } from "react";
import { useWebSocket } from "../../../hooks";
import { QICustomDateRangePicker, QIDragAndDropDetails, QISwitch } from "../../../components";
import moment from "moment";
import { TripsContainer } from "../Vehicles/Trips";
import { EventsContainer } from "../Vehicles/Events";
import sprite from "../../../assets/icons.svg";
//Data config json
import { HeaderDetails, ListDetails } from "../Shared/VehicleDetailsItems";
//Toast Message
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//TrackPoints
import TrackPoints from "../Vehicles/TrackPoints";
//Raw Data
import { RawData } from "../Vehicles/RawData";
//Trips
import Activities from "../Vehicles/Activities";
import { useDispatch } from "react-redux";
import { setVariableDatas } from "../../../reduxStore/features";
import { getLocalizedString } from "../../../shared/translation";

export const DeviceContainer = () => {
  const dispatch = useDispatch();

  const [clientId, setClientId] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [sourceIds, setSourceIds] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(0);
  const [mapData, setMapData] = useState({});
  const [trackingPoints, setTrackingPoints] = useState([]);
  const [selectedVehiclePlate, setselectedVehiclePlate] = useState(null);
  const [selectedDeviceName, setselectedDeviceName] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [dockAlign, setDockAlign] = useState(null);
  const [mapExpand, setMapExpand] = useState(false);
  const [showTrack, setShowTrack] = useState(false);
  const [trackMapRef, setTrackMapRef] = useState(null);
  const [simpleSearch, setSimpleSearch] = useState("");
  const [currentTab, setCurrentTab] = useState("Map");
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });
  const [resetCount, setResetCount] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(null);
  // Config Set
  const [showConfig, setShowConfig] = useState(false);
  const [checkData, setCheckData] = useState([]);
  const [clientConfig, setClientConfig] = useState({});
  const [maximizeMinimize, setmaximizeMinimize] = useState(false);
  const [toggleTrackPointTab, setToggleTrackPointTab] = useState(false);
  const [decode, setDecode] = useState(false);
  const [order, setOrder] = useState(false);
  const [variableData, setVariableData] = useState({});
  const [variablePage, setVariablePage] = useState(0);
  const [vehicleData, setVehicleData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true); // Flag to check if initial load

  //Ref
  const configDivRef = useRef(null);
  const configButtonRef = useRef(null);

  //Calculate Latest Date
  useEffect(() => {
    try {
      setSelectedRange({
        startDate: moment().subtract(24, "hours"),
        endDate: moment(),
      });
    } catch (e) {}
  }, [deviceId]);

  const openDeviceModal = () => {
    setShowDetails(false);
    addNewBox(deviceId);
  };
  const { realTimedata } = useWebSocket(String(clientId), "sub_all");

  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => {
        return item.id === selectedClientId;
      }
    );
    setClientId(currentUser?.client?.id);
    try {
      if (currentUser?.client?.config_json) {
        setClientConfig(JSON.parse(currentUser?.client?.config_json));
      }
    } catch (e) {
      console.log(e);
    }

    //clean up client id on component unmount
    return () => setClientId(null);
  }, []);

  /* Get vehicle position and plot in map */
  const { data: trackingData = { data: [] } } = useGetDevicesTrackingQuery({
    device_ids: sourceIds,
    // fields: "device_data.source_id,device_data.gps,device_data.velocity,device_data.ignition",
  });

  /* Get Variable Data Mappings */

  const { data: mappingData } = useGetVariablesDataQuery({ per_page: 2000, page: variablePage });

  /* Load data to get data for show details */
  useEffect(() => {
    let device;
    if (trackingData && trackingData?.latest_data?.length) {
      device =
        (trackingData?.latest_data || [])?.find(
          ({ device_data }) => device_data?.source_id === deviceId
        ) || {};
      setDeviceData(device?.device_data || {});
    }
    if (trackingData && !trackingData?.latest_data?.length) {
      setDeviceData({});
    }
  }, [trackingData, deviceId]);

  // Returns the latest time
  const getLatestTime = (t1, t2, t3, t4) => {
    let validTime1 = 0,
      validTime2 = 0,
      validTime3 = 0,
      validTime4 = 0;
    if (typeof t1 == "number") {
      validTime1 = t1;
    }
    if (typeof t2 == "number") {
      validTime2 = t2;
    }
    if (typeof t3 == "number") {
      validTime3 = t3;
    }
    if (typeof t4 == "number") {
      validTime4 = t4;
    }

    return Math.max(validTime1, validTime2, validTime3, validTime4);
  };

  useEffect(() => {
    if (!trackingData?.latest_data) return;

    const filteredPosition = {};
    const trackingPoints = [];

    trackingData.latest_data.forEach((data) => {
      const sourceId = data?.device_data?.source_id;
      const realTimeDataForSource = realTimedata[sourceId];
      const position = realTimeDataForSource?.gps?.position || data?.device_data?.gps?.v?.position;
      const direction =
        realTimeDataForSource?.velocity?.direction || data?.device_data?.velocity?.v?.direction;
      let speed = realTimeDataForSource?.velocity?.speed || data?.device_data?.velocity?.v?.speed;

      const sourceTime = getLatestTime(
        realTimeDataForSource?.source_time,
        data?.device_data?.source_time?.v,
        data?.device_data?.source_time,
        data?.heartbeat?.source_time?.v
      );
      const serverTime =
        realTimeDataForSource?.server_time ||
        data?.device_data?.server_time?.v ||
        data?.device_data?.server_time;
      const plate_number = data?.device_data?.plate_number?.v;
      // Trim speed to 2 decimal points and add unit
      speed = speed == 0 || speed ? `${(speed * 3.6).toFixed(1)} km/h` : "Not Available";
      // Use real-time data for ignition if available, otherwise fallback to data?.device_data
      const ignition =
        realTimeDataForSource?.ignition ?? data?.device_data?.ignition?.v ?? "Unknown";

      if (sourceId && position && position.lat !== 0 && position.lng !== 0) {
        filteredPosition[sourceId] = {
          speed,
          direction,
          ignition,
          sourceTime,
          plate_number,
          serverTime,
        };
        const updatedPosition = { ...position, imei: sourceId, direction, speed, plate_number };
        trackingPoints.push(updatedPosition);
      }
    });

    setMapData(filteredPosition);
    setTrackingPoints(trackingPoints.filter((device) => device.lat !== 0 && device.lng !== 0));
  }, [trackingData, realTimedata, simpleSearch]);

  /* Variable Data Mapping */
  useEffect(() => {
    if (mappingData?.data?.length) {
      mappingData?.data?.map((item, id) => {
        const format = item?.section_value;
        setVariableData((prev) => ({
          ...prev,
          [`${format ? format + "." : ""}${item?.name}`]: { ...item },
        }));
        return null;
      });
      setVariablePage((prev) => prev + 1);
    }
  }, [mappingData]);

  /* Dispatch Variable Data to Global Store */
  useEffect(() => {
    if (variableData) {
      dispatch(setVariableDatas(variableData));
    }
  }, [variableData, dispatch]);

  //Map tracking
  useEffect(() => {
    if (mapRef && trackingPoints.length) {
      let layerName = `test_${count}`;
      count && mapRef.removeLayer(`test_${count - 1}`);
      mapRef.addLayer({ name: layerName });
      mapRef.drawTrack({
        layerName: layerName,
        fitWithinView: true,
        trackType: "point",
        data: [{ coordinates: trackingPoints }],
        style: {
          point: {
            style: (feature) => {
              return new Style({
                image: new Icon({
                  color: "#fff",
                  crossOrigin: "anonymous",
                  src: locationImg,
                  imgSize: [26, 26],
                  rotation: feature.direction,
                }),
                text: new Text({
                  text: feature.plate_number || feature.imei,
                  font: "14px Calibri,sans-serif",
                  fill: new Fill({
                    color: "#000", // Black font
                  }),
                  backgroundFill: new Fill({
                    color: "#fff", // White background for the box
                  }),
                  padding: [5, 5, 5, 5], // Padding inside the box
                  offsetY: -30, // Moves the text box above the marker
                  textAlign: "center", // Aligns the text in the center of the box
                  backgroundStroke: new Stroke({
                    color: "#000", // Border color around the box
                    width: 1, // Border width
                  }),
                }),
              });
            },
          },
        },
      });
      mapRef.performFit(layerName);
    } else {
      mapRef?.removeLayer(`test_${count - 1}`);
    }
    try {
      //Navigating back and forth from Live Tracking
      deviceId && showPontOnMap(deviceId, selectedVehiclePlate);
    } catch (e) {}
    setCount((c) => c + 1);
  }, [mapRef, trackingPoints, deviceId]);
  // Show single point on map (on selection)
  function showPontOnMap(data_id, plate_number) {
    let imei_info = trackingPoints.find((device_data) => device_data.imei === data_id);
    let layerName = `imei-details_${selectedRow}`;
    // selectedRow && mapRef.removeLayer(`imei-details_${selectedRow - 1}`);
    selectedRow && mapRef.removeLayerSource(`imei-details_${selectedRow - 1}`);
    mapRef.addLayer({ name: layerName });
    mapRef.drawTrack({
      layerName: layerName,
      fitWithinView: false,
      trackType: "point",
      data: [{ coordinates: [imei_info] }],
      style: {
        point: {
          style: (feature) => {
            const isSelected = feature.plate_number === selectedVehiclePlate;

            return new Style({
              zIndex: isSelected ? 10 : 1, // Ensure selected vehicle is on top
              image: new Icon({
                color: "#fff",
                crossOrigin: "anonymous",
                src: locArrowImg,
                imgSize: [36, 36],
                rotation: feature.direction,
              }),
            });
          },
        },
      },
      tooltip: {
        showOn: "hover",
        content: (feature) => {
          let data = feature.get("data");
          return `<div><strong>${
            plate_number || data?.plate_number || data?.imei || ""
          }</strong></div>
          <div>${data?.address?.name || data?.address?.city || data?.address?.country || ""}</div>
          <div>${data?.speed || ""}</div>`;
        },
      },
    });
    // Only fit the map on initial load
    if (initialLoad) {
      mapRef.performFit(layerName);
      setInitialLoad(false); // Disable initial load after first fit
    }
    //mapRef.performFit(layerName);
    setSelectedRow((i) => i + 1);
  }

  const removeLayerOnClose = () => {
    mapRef && mapRef.removeLayerSource(`imei-details_${selectedRow - 1}`);
    trackMapRef && trackMapRef.removeLayerSource(`track_${deviceId}`);
  };

  const removeRowSelection = () => {
    setHighlight(null);
    setSelectedRowId(null);
  };

  const { activeBoxes, addNewBox, removeBox, revertBox, isItemsDragging, handleDragChange } =
    useDndContainer();

  //Show Map
  const funSetActive = () => {
    setCurrentTab("Map");
    setShowTrack(false);
    setDockAlign("cross");
    setTimeout(() => {
      deviceId && setDockAlign("split-down");
    }, 0);
  };
  //Show Live Track
  const showLiveTrack = () => {
    setDockAlign("cross");
    setCurrentTab("LiveTrack");
    setShowTrack(true);
    setTimeout(() => {
      deviceId && setDockAlign("split-down");
    }, 0);
  };

  //Show TrackPoints, Trips and Reports
  const showdetailsTabs = (tab) => {
    setShowDetails(false);
    setDockAlign("cross");
    setCurrentTab(tab);
  };

  //clear live track on type
  useEffect(() => {
    if (simpleSearch) {
      setShowTrack(false);
      setDeviceId(null);
      setDockAlign("cross");
      setHighlight(null);
    }
  }, [simpleSearch]);

  //Hide Vehicle Details for Track Points
  useEffect(() => {
    if (
      currentTab !== "TrackPoints" &&
      currentTab !== "Reports" &&
      currentTab !== "Trips" &&
      currentTab !== "Activities" &&
      currentTab !== "Events"
    ) {
      selectedDeviceName && setShowDetails(true);
    }
  }, [selectedDeviceName, currentTab]);

  //Column config close on clicking outside the column config div
  const handleClickOutside = (event) => {
    if (
      configButtonRef.current &&
      configDivRef.current &&
      !configDivRef.current.contains(event.target) &&
      !configButtonRef.current.contains(event.target)
    ) {
      // Clicked outside the config div, set showConfig to false
      setShowConfig(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  return (
    <>
      <main className="tracking-app-main-container">
        <DeviceList
          setDetails={setShowDetails}
          setDeviceId={setDeviceId}
          setSourceIds={setSourceIds}
          sourceIds={sourceIds}
          showPontOnMap={showPontOnMap}
          mapData={mapData}
          setselectedVehiclePlate={setselectedVehiclePlate}
          setselectedDeviceName={setselectedDeviceName}
          setHighlight={setHighlight}
          highlight={highlight}
          dockAlign={dockAlign}
          setDockAlign={setDockAlign}
          setShowDetails={setShowDetails}
          removeLayerOnClose={removeLayerOnClose}
          mapExpand={mapExpand}
          setMapExpand={setMapExpand}
          trackMapRef={trackMapRef}
          deviceId={deviceId}
          setShowTrack={setShowTrack}
          setSimpleSearch={setSimpleSearch}
          simpleSearch={simpleSearch}
          currentTab={currentTab}
          removeRowSelection={removeRowSelection}
          selectedRowId={selectedRowId}
          setSelectedRowId={setSelectedRowId}
          vehicleData={vehicleData}
          setVehicleData={setVehicleData}
          setCurrentTab={setCurrentTab}
        />

        <article className={`tracking-app-main-container_block ${currentTab}`}>
          <header className="tracking-app-main-container_block_header">
            <nav className="map-view-tabs">
              <ul className="map-and-details_tabs">
                <li>
                  <span
                    className={`map-and-details_tabs_item ${currentTab === "Map" && "active"}`}
                    onClick={funSetActive}
                  >
                    {getLocalizedString("map", "Map")}
                  </span>
                </li>
                {selectedRowId !== null && (
                  <li>
                    <span
                      className={`map-and-details_tabs_item ${
                        currentTab === "LiveTrack" && "active"
                      }`}
                      onClick={() =>
                        deviceId
                          ? showLiveTrack()
                          : toast.error(
                              getLocalizedString("no_device_selected", "No Device Selected")
                            )
                      }
                    >
                      {getLocalizedString("live_and_history", "Live & History")}
                      <span
                        className={
                          realTimedata?.[deviceId] && realTimedata?.[deviceId]?.ignition
                            ? "live-icon"
                            : ""
                        }
                      ></span>
                    </span>
                  </li>
                )}
                {selectedRowId !== null && (
                  <li>
                    <span
                      className={`map-and-details_tabs_item ${
                        currentTab === "TrackPoints" && "active"
                      }`}
                      onClick={() =>
                        deviceId
                          ? showdetailsTabs("TrackPoints")
                          : toast.error(
                              getLocalizedString("no_device_selected", "No Device Selected")
                            )
                      }
                    >
                      {getLocalizedString("track_points", "Track Points")}
                    </span>
                  </li>
                )}
                {selectedRowId !== null && (
                  <li>
                    <span
                      className={`map-and-details_tabs_item ${
                        currentTab === "Activities" && "active"
                      }`}
                      onClick={() =>
                        deviceId
                          ? showdetailsTabs("Activities")
                          : toast.error(
                              getLocalizedString("no_device_selected", "No Device Selected")
                            )
                      }
                    >
                      {getLocalizedString("activities", "Activities")}
                    </span>
                  </li>
                )}
                {selectedRowId !== null && (
                  <li>
                    <span
                      className={`map-and-details_tabs_item ${currentTab === "Trips" && "active"}`}
                      onClick={() =>
                        deviceId
                          ? showdetailsTabs("Trips")
                          : toast.error(
                              getLocalizedString("no_device_selected", "No Device Selected")
                            )
                      }
                    >
                      {getLocalizedString("trips", "Trips")}
                    </span>
                  </li>
                )}
                {selectedRowId !== null && (
                  <li>
                    <span
                      className={`map-and-details_tabs_item ${currentTab === "Events" && "active"}`}
                      onClick={() =>
                        deviceId
                          ? showdetailsTabs("Events")
                          : toast.error(
                              getLocalizedString("no_device_selected", "No Device Selected")
                            )
                      }
                    >
                      {getLocalizedString("events", "Events")}
                    </span>
                  </li>
                )}
              </ul>
            </nav>

            {/* Date Range Picker and Column Config Setting */}
            {currentTab !== "Map" && (
              <QICustomDateRangePicker
                resetCount={resetCount}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                setResetCount={setResetCount}
                openCalendar="left"
              />
            )}
          </header>

          <article
            className={`map-and-details ${dockAlign} ${
              maximizeMinimize ? "minimized-details" : ""
            }`}
          >
            {currentTab === "TrackPoints" && (
              <section className="tracking-app-main-container_block_header_secondary">
                {/* Date Range Picker and Column Config Setting */}
                <div className="raw-data-section">
                  <QISwitch
                    label="Raw Data"
                    value={toggleTrackPointTab}
                    onChange={(value) => {
                      setToggleTrackPointTab((prev) => !prev);
                    }}
                    disabled={false}
                    readOnly={false}
                  />

                  {toggleTrackPointTab && (
                    <>
                      <QISwitch
                        label="Decode"
                        value={decode}
                        onChange={(value) => {
                          setDecode((prev) => !prev);
                        }}
                        title={
                          decode
                            ? getLocalizedString(
                                "data_will_be_blank_if_format_is_binary",
                                "Data will be blank if format is binary"
                              )
                            : getLocalizedString(
                                "data_will_be_displayed_in_base64encoded_format",
                                "Data will be displayed in base64encoded format"
                              )
                        }
                        disabled={false}
                        readOnly={false}
                      />

                      <QISwitch
                        label={order ? "Asec" : "Desc"}
                        value={order}
                        onChange={(value) => {
                          setOrder((prev) => !prev);
                        }}
                        title={""}
                        disabled={false}
                        readOnly={false}
                      />
                    </>
                  )}
                </div>

                {!toggleTrackPointTab && (
                  <strong
                    className="config-button"
                    ref={configButtonRef}
                    onClick={() => {
                      checkData?.length > 0
                        ? setShowConfig((prev) => !prev)
                        : toast.error(
                            getLocalizedString(
                              "no_column_data_available",
                              "No column data available"
                            )
                          );
                    }}
                    title="Column Configuration"
                  >
                    <svg className="icon">
                      <use href={`${sprite}#settings`}></use>
                    </svg>

                    <span> Column Configuration</span>
                  </strong>
                )}
              </section>
            )}

            {currentTab === "Map" ? (
              <VehicleMap
                mapExpand={mapExpand}
                mapRef={mapRef}
                dockAlign={dockAlign}
                setMapRef={setMapRef}
                deviceId={deviceId}
                selectedRowId={selectedRowId}
                maximizeMinimize={maximizeMinimize}
              />
            ) : currentTab === "LiveTrack" ? (
              <VehicleTrack
                dockAlign={dockAlign}
                deviceId={deviceId}
                realTimeData={realTimedata[deviceId]}
                setTrackMapRef={setTrackMapRef}
                trackMapRef={trackMapRef}
                mapExpand={mapExpand}
                selectedRange={selectedRange}
                setResetCount={setResetCount}
                maximizeMinimize={maximizeMinimize}
              />
            ) : currentTab === "TrackPoints" ? (
              toggleTrackPointTab ? (
                <RawData
                  deviceId={deviceId}
                  selectedRange={selectedRange}
                  decode={decode}
                  order={order}
                />
              ) : (
                <TrackPoints
                  deviceId={deviceId}
                  liveData={realTimedata}
                  selectedVehiclePlate={selectedVehiclePlate}
                  selectedRange={selectedRange}
                  setResetCount={setResetCount}
                  showConfig={showConfig}
                  setCheckData={setCheckData}
                  configDivRef={configDivRef}
                />
              )
            ) : currentTab === "Activities" ? (
              <Activities
                deviceId={deviceId}
                selectedRange={selectedRange}
                clientId={clientId}
                type="Devices"
              />
            ) : currentTab === "Trips" ? (
              <TripsContainer
                resetCount={resetCount}
                setResetCount={setResetCount}
                deviceId={deviceId}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                clientId={clientId}
                selectedVehiclePlate={selectedVehiclePlate}
                type="Devices"
              />
            ) : currentTab === "Events" ? (
              <EventsContainer
                resetCount={resetCount}
                setResetCount={setResetCount}
                deviceId={deviceId}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                clientId={clientId}
                type="Devices"
              />
            ) : null}

            {showDetails === true && (
              <article className={`details-block`}>
                <QIDragAndDropDetails
                  setDetails={setShowDetails}
                  setPopup={openDeviceModal}
                  deviceData={deviceData || {}}
                  removeLayerOnClose={removeLayerOnClose}
                  selectedVehiclePlate={selectedDeviceName}
                  removeRowSelection={removeRowSelection}
                  setDockAlign={setDockAlign}
                  dockAlign={dockAlign}
                  deviceId={deviceId}
                  realTimedata={realTimedata}
                  setDeviceId={setDeviceId}
                  showTrack={showTrack}
                  setShowTrack={setShowTrack}
                  headerName={getLocalizedString("device_name", "Device Name")}
                  headerDetails={
                    <HeaderDetails
                      realTimedata={realTimedata}
                      deviceData={deviceData}
                      deviceId={deviceId}
                      type="Devices"
                    />
                  }
                  listDetails={
                    <ListDetails
                      clientConfig={clientConfig}
                      realTimedata={realTimedata}
                      deviceData={deviceData}
                      deviceId={deviceId}
                    />
                  }
                  currentTab={currentTab}
                  maximizeMinimize={maximizeMinimize}
                  setmaximizeMinimize={setmaximizeMinimize}
                />
              </article>
            )}
          </article>
        </article>

        <div>
          <MinimisedTab layoutName="trackingLayout" />
        </div>
      </main>

      <Dnd
        layoutName="trackingLayout"
        isItemsDragging={isItemsDragging}
        handleDragChange={handleDragChange}
        setDetails={setShowDetails}
      >
        {Object.keys(activeBoxes).map((key) => (
          <WindowTab
            key={key}
            id={key}
            label={key}
            {...activeBoxes[key].props}
            onClose={removeBox}
            onMinimise={revertBox}
            setShowDetails={setShowDetails}
            setDockAlign={setDockAlign}
            setDeviceId={setDeviceId}
            setShowTrack={setShowTrack}
            selectedVehiclePlate={selectedDeviceName}
            headerName={getLocalizedString("device_name", "Device Name")}
            setHighlight={setHighlight}
            removeLayerOnClose={removeLayerOnClose}
            removeRowSelection={removeRowSelection}
          >
            <VehicleDetailsWindowView
              realTimedata={realTimedata}
              deviceId={deviceId}
              deviceData={deviceData}
              clientConfig={clientConfig}
              summaryDetails={
                <HeaderDetails
                  realTimedata={realTimedata}
                  deviceData={deviceData}
                  deviceId={deviceId}
                  type="Devices"
                />
              }
              listDetails={
                <ListDetails
                  clientConfig={clientConfig}
                  realTimedata={realTimedata}
                  deviceData={deviceData}
                  deviceId={deviceId}
                />
              }
            />
          </WindowTab>
        ))}
      </Dnd>
      <ToastContainer />
    </>
  );
};
